<template>
  <div class="testPaperCenterSty">
    <!-- 单选 -->
    <div class="contentSty">
      <div class="cardTitle">
        <div>
					<span>
						题型:{{ worngBookDetails.questionType | formatQuestionType }}
					</span>

        </div>
        <div>
          <p>
            {{ worngBookDetails.createTime }}
          </p>
          <div>
            <el-button type="text"  @click="deleteWrongRecord(worngBookDetails.id)" icon="el-icon-delete">我懂了</el-button>
          </div>

        </div>

      </div>

      <radioSubject v-if="(questionBody.questionType == 0) || (questionBody.questionType == 1) " :subjectId="subjectId"
                    :questionBody="questionBody" :haveAnswer="true" :isDis="true"
                    :chooseItem="questionBody.answerInfo.content">
      </radioSubject>
      <checkSubject v-if="questionBody.questionType == 2" :subjectId="subjectId" :questionBody="questionBody"
                    :haveAnswer="true" :isDis="true">
      </checkSubject>
      <shortAnswerSubject v-if="questionBody.questionType == 3" :subjectId="subjectId" :questionBody="questionBody"
                          :haveAnswer="true" :isDis="true">
      </shortAnswerSubject>
      <programmingSubject v-if="questionBody.questionType == 4" :subjectId="subjectId"
                          :questionBody="questionBody" :isDis="true" :haveAnswer="true">
      </programmingSubject>
      <programmingSubjectMore v-if="questionBody.questionType == 5" :subjectId="subjectId"
                              :questionBody="questionBody" :isDis="true" :haveAnswer="true">
      </programmingSubjectMore>
    </div>
  </div>
</template>

<script>
import radioSubject from "@/components/radioSubject.vue"
import checkSubject from "@/components/checkSubject.vue"
import shortAnswerSubject from "@/components/shortAnswerSubject.vue"
import programmingSubject from "@/components/programmingSubject.vue"
import programmingSubjectMore from "@/components/programmingSubjectMore.vue"
import {
  checkCountByAnswerPaperAndDay,
  deleteWrongBookQuestion,
  wrongBookDetails
} from "@/api/index.js"

export default {
  filters: {
    formatQuestionType(type) {
      switch (type) {
        case 0:
          return "单选题"
          break;
        case 1:
          return "判断题"
          break;
        case 2:
          return "多选题"
          break;
        case 3:
          return "简答题"
          break;
        case 4:
          return "编程题"
          break;
        case 5:
          return "填空题"
          break;
        default:
          break;
      }
    }
  },
  props: {
    worngBookDetails: {
      type: Object,
      default: () => ({})
    },
    subjectId: {
      type: Number,
      default: 1
    }
  },
  components: {
    radioSubject,
    checkSubject,
    shortAnswerSubject,
    programmingSubject,
    programmingSubjectMore
  },
  mounted() {
    let param = {
      id: this.worngBookDetails.id
    }
    wrongBookDetails(param).then(res => {
      if (res.code === "0000") {
        this.questionReadVM = res.data.questionReadVM
        this.answerRequest = res.data.answerRequest
        this.questionBody = res.data.questionReadVM
        this.questionBody.answerInfo = res.data.answerRequest
      }
    }).catch(() => {
      this.$router.push({
        path: '/'
      })
    });
  },

  data() {
    return {
      value1: 4.5,
      questionReadVM: {},
      answerRequest: {},
      questionBody: {}
    }
  },
  methods: {

    deleteWrongRecord(id) {
      deleteWrongBookQuestion(id).then(res => {

        if (res.code === "0000") {
          location.reload()
        } else {
          this.$message({
            message: res.message,
            type: 'warning'
          });
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.testPaperCenterSty {
  width: 1280px;
  margin-top: 20px;
  min-height: 300px;
  // background-color: #FFFFFF;
  position: relative;

  .cardTitle {
    background-color: #67C23A;
    height: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;

    div:first-child {
      span {
        line-height: 30px;
        font-weight: 800;
        color: #FFFFFF;
      }

      span:last-child {
        margin-left: 10px;
      }
    }

    div:last-child {
      p {
        padding: 0 5px;
        line-height: 30px;
        height: 30px;
        background-color: #E6A23C;
        font-weight: 800;
      }
    }
  }

  .contentSty {
    padding-bottom: 30px 30px;
    margin-top: 20px;

    .box-card {
      text-align: left;
      padding: 0 30px;
      // margin-top: 10px;

      .title {
        font-size: 18px;
        font-weight: 800;
        line-height: 30px;
      }

      .content {
        font-size: 14px;
        margin-top: 20px;
        color: #808080;

        span {
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
}
</style>
